<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- Titel en logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- Tekstjes -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welkom bij BlokPass! 👋🏻
          </p>
          <p class="mb-2">
            Log je nog even in, aub?
          </p>
        </v-card-text>

        <!-- Inlogformulier -->
        <v-card-text>
          <!-- Formulier -->
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation
            @submit.prevent="login"
          >
            <!-- Email -->
            <v-text-field
              v-model="form.fields.username.value"
              outlined
              :rules="form.fields.username.rules"
              label="E-mail adres"
              class="mb-3"
              style="margin: 0;"
              autocomplete="username"
              required
            ></v-text-field>

            <!-- Password -->
            <v-text-field
              v-model="form.fields.password.value"
              outlined
              :append-icon="form.fields.password.show ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="form.fields.password.rules"
              :type="form.fields.password.show ? 'text' : 'password'"
              name="input-10-1"
              label="Wachtwoord"
              autocomplete="current-password"
              @click:append="form.fields.password.show = !form.fields.password.show"
            ></v-text-field>

            <!-- <v-btn
              :disabled="!form.valid"
              color="success"
              class="mr-4"
              @click="validate"
            >
              Validate
            </v-btn> -->

            <!-- <v-btn
              color="error"
              class="mr-4"
              @click="reset"
            >
              Reset Form
            </v-btn> -->

            <v-btn
              block
              color="primary"
              :disabled="!form.valid"
              :loading="loginLoading"
              type="submit"
            >
              Inloggen
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- Divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">of</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- Alternative login methods -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="loginMethod in loginMethods"
            :key="loginMethod.name"
            icon
            class="ms-1"
            @click="loginViaAlternativeMethod(loginMethod)"
          >
            <v-icon :color="$vuetify.theme.dark ? loginMethod.style.colorInDark : loginMethod.style.color">
              {{ loginMethod.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig'

// Icons
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiGoogle, mdiMicrosoftOffice, mdiQrcode,
} from '@mdi/js'

// Axios
import axios from '@axios'

export default {
  data: () => ({
    // themeConfig
    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,

    // Icons
    icons: {
      mdiEyeOffOutline,
      mdiEyeOutline,
    },

    // Form data
    form: {
      valid: true,
      fields: {
        username: {
          value: '',
          rules: [
            v => !!v || 'E-mail is verplicht',
            v => /.+@.+/.test(v) || 'E-mail is niet geldig',
          ],
        },
        password: {
          value: '',
          show: false,
          rules: [
            v => !!v || 'Wachtwoord is verplicht',
          ],
        },
      },
    },

    // Login loading
    loginLoading: false,

    // Login methods
    loginMethods: [
      // Google
      {
        name: 'google',
        icon: mdiGoogle,
        style: {
          color: '#db4437',
          colorInDark: '#db4437',
        },
      },

      // QR code
      {
        name: 'qr',
        icon: mdiQrcode,
        style: {
          color: '#4a4948',
          colorInDark: '#d9d8d7',
        },
      },

      // Office
      {
        name: 'office',
        icon: mdiMicrosoftOffice,
        style: {
          color: '#D83B01',
          colorInDark: '#D83B01',
        },
      },
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    async login() {
      // Router test
      console.log(this.$router)

      // First, validate the form
      this.validate()

      // Enable the loading
      this.loginLoading = true

      try {
        // Send the login request
        const loggedInRequest = await axios.post('/auth/password/login', { username: this.form.fields.username.value, password: this.form.fields.password.value })

        // If the status is not 200, dig
        if (loggedInRequest.status !== 200) {
          switch (loggedInRequest.status) {
            case 401:
              this.$toast('error', 'Foutief wachtwoord', 'Probeer het opnieuw')
              break
            case 404:
              this.$toast('error', 'E-mailadres niet gevonden', 'Probeer het opnieuw')
              break
            case 500:
              this.$toast('error', 'Er is een onverwachte fout opgetreden', 'Probeer het opnieuw')
              break
            default:
              this.$toast('error', 'Er is een onverwachte fout opgetreden', 'Probeer het opnieuw')
              break
          }

          return false
        }

        // Authenticatie is ok, token halen
        const { token } = loggedInRequest.data
        localStorage.setItem('accessToken', token)

        // get exp from jwt
        const jwt = token.split('.')[1]
        const decodedJwt = JSON.parse(window.atob(jwt))
        localStorage.setItem('exp', decodedJwt.exp)

        // Gebruikersgegevens ophalen via token
        const userRequest = await axios.get('/account/me')
        if (userRequest.status === 200) {
          const { account } = userRequest.data
          localStorage.setItem('userData', JSON.stringify(account))
          console.log('Set account data')

          const { abilities } = account
          localStorage.setItem('userAbility', JSON.stringify(abilities))

          // vm.$ability.update(ability)

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete account.abilities

          setTimeout(() => {
            this.$router.push('/school-picker').catch(err => {
              console.log(err)
              this.loginLoading = false
            })
          }, 1000)

          return true
        }
        this.$toast('error', 'Er is een onverwachte fout opgetreden', 'Probeer het later opnieuw')

        return false
      } finally {
        this.loginLoading = false
      }
    },
    loginViaAlternativeMethod(loginMethod) {
      switch (loginMethod.name) {
        case 'google':
          window.location.href = 'https://api.blokpass.be/auth/google?authtype=admin'
          break
        case 'office':
          window.location.href = 'https://api.blokpass.be/auth/microsoft?authtype=admin'
          break
        default:
          this.$toast('info', 'Onbekende login methode', 'Deze functie is nog niet beschikbaar')
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
